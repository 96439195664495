window.addEventListener('load', function () {

    // mobile menu
    $('.menu-btn').on('click', function() {
      
        $(this).toggleClass('menu-btn_active');

        $('.nav_menu').addClass('menu_active');
        
        if(!$(this).hasClass('menu-btn_active')){
            $('.nav_menu').removeClass('menu_active');
        }
    });
    // mobile menu end

    // fixed menu
    window.onscroll = function() {myFunction()};
	var nav = document.querySelector('nav');
	var sticky = nav.offsetTop;
    var mainn = document.querySelector('main');

    function myFunction() {
        if (nav.classList.contains('nofix')){
            nav.classList.remove('sticky');
        }
		else if (window.pageYOffset > sticky) {
			nav.classList.add('sticky');
            mainn.style.marginTop = '100px';
        } 

        else {
			nav.removeAttribute('class');
            mainn.style.marginTop = '0px';
		}
    }
    // fixed menu end

    // up

    var $btn = $('.btn-up');
    var h = 300;
    var btnShowed = false;
    
    function onScrollEnd(){
        var scrollTop = $(this).scrollTop();
        
        if(!btnShowed && scrollTop >= h){
            $btn.fadeIn(20);
            btnShowed = true;
        }
        else if(btnShowed && scrollTop < h){
            $btn.fadeOut(20);
            btnShowed = false;
        }
    }
    
    var scrollTimer;
    
    $(document).on('scroll', function(){
        var document = this;
        
        if(scrollTimer !== undefined){
            clearInterval(scrollTimer);
        }
        
        scrollTimer = setTimeout(function(){
            onScrollEnd.call(document);
        }, 200);
    });

    $btn.on('click', function(){
        $('html, body').animate({
            scrollTop: 0
        }, 700);
    });

    
    onScrollEnd.call(document);

    // up end

    // drop menu
    
    $('.dropBtn').on('mouseover', function () {
        $('.dropMenu').slideDown(300);
        $(this).addClass('ac');
    });

    $('.dropBtn').on('click', function (e) {
        e.preventDefault();
        $('.dropMenu').slideDown(300);
    }).on('mouseleave', function () {
        $('.dropMenu').css('display', 'none');
        $(this).removeClass('ac');
    });

    $('.dropMenu').on('mouseover', function () {
        $('.dropMenu').css('display', 'block');
        $('.dropBtn').addClass('ac');
    }).on('mouseleave', function () {
        $('.dropMenu').css('display', 'none');
        $('.dropBtn').removeClass('ac');
    });

    $(document).on('contextmenu', function(e){
        e.preventDefault();
    });

    
});